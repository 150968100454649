<template>
  <div class="media">

    <div class="media-wrapper">
      <div class="zlxc-container" style="overflow: visible">
        <!-- <div class="bread-crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>理论学习</el-breadcrumb-item>
            <el-breadcrumb-item to="videoLearning">视频学习</el-breadcrumb-item>
          </el-breadcrumb>
        </div> -->
        <iframe name="theoryId" src="https://www.hzsjspx.com/pcTheory/scienceStudy" allowfullscreen=“true”  frameborder="0" width="100%" height="800px">
          您的浏览器不支持框架集，请切换浏览器浏览~
        </iframe>
        <div class="div-50" ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  computed: {
  },
  created() { },
  methods: {

  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
